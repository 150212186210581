import React, { Component } from 'react'
import styles from './Footer.module.css'
import {Link} from 'react-router-dom'


class Footer extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <div className={styles.block}>
                        <p>Wopii Development AB</p>
                        <p>Kungsgatan 32</p>
                        <p>419 11 Göteborg</p>
                        <p>info@wopii.com</p>
                        <p>+46 705 57 47 54</p>
                    </div>
                    <div className={styles.block}>
                        {this.props.pages.map(page => {
                            return(
                                <Link onClick={() => window.scrollTo(0,0)} className={styles.link} key={page.id} to={`/${page.slug}`}>{page.title.rendered}</Link>
                            )
                            
                        })}
                    </div>
                    <div className={styles.block}>
                        <p>Tekniska problem?</p>
                        <p>hej@wopii.com</p>
                    </div>
                </div>
                <p className={styles.end}>© All rights reserved, Wopii Development AB 2019 -Sweden</p>
            </div>
        )
    }
}

export default Footer
