import React, { Component } from 'react'
import PageWithHTML from  '../PageWithHTML'
import Portfolio from '../../Pages/Portfolio'
import Contact from '../../Pages/Contact'
import Start from '../../Pages/Start'
import About from '../../Pages/About'
import styles from './Page.module.css'
import {Helmet} from 'react-helmet'
import Footer from '../Footer/Footer'
import Blog from '../../Pages/Blog'
import BlogPost from '../../Components/BlogPost'

class Page extends Component {

    getMeta() {
        return (
            <Helmet>
                <meta name="description" content={this.props.description} />
                <title>{'Wopii - '+this.props.title}</title>
                <meta property="og:title" content={this.props.title} />
                <meta property="og:description" content={this.props.description} />
                <meta property="og:image" content={this.props.metaImage} />
            </Helmet>
        )
    }

    renderPage() {
        const props = {
            content: this.props.content,
            secondaryContent: this.props.secondaryContent,
            thirdContent: this.props.thirdContent,
            featuredMedia: this.props.featuredMedia,
            gallery: this.props.gallery,
            description: this.props.description,
            secondaryMedia: this.props.secondaryMedia
        }
        switch(this.props.template) {
            case 'portfolio.php':
                return (
                    <Portfolio {...props} />        
                )
            case 'contact.php':
                return (
                    <Contact {...props} />
                )
            case 'start.php':
                return (
                    <Start {...props} />
                ) 
            case 'about.php':
                return (
                    <About {...props} />
                )
            case 'blog.php':
                return (
                    <Blog {...props} />
                )
            case 'blogpost':
                    return (
                        <BlogPost post={this.props.content} />
                    )
            default:
                return (
                    <PageWithHTML {...props} />
                )
        } 
    }

    render() {
        console.log(this.props)
        return (
            <div className={styles.body}>
                {this.getMeta()}
                {this.renderPage()} 
                {/* Should get content from wordpress  */}
                <Footer pages={this.props.pages} />     
            </div>
        )
        
    }
}

export default Page
