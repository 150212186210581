import React from 'react';
import './index.css';
import App from './App';
import {render} from 'react-dom'
import registerServiceWorker from './registerServiceWorker';
// import WebFont from 'webfontloader';

// WebFont.load({
//   google: {
//     families: ['Raleway', 'sans-serif']
//   }
// });

render(<App />, document.getElementById('root'));

registerServiceWorker();
