import React, { Component } from 'react'
import styles from './MinifiedBlog.module.css'
import BlogCard from '../BlogCard'


class MinifiedBlog extends Component {
    render() {
        const {posts} = this.props
        return (
            <div className={styles.container}>
                <h4>Fler inlägg...</h4>
                <div className={styles.cards}>
                    {posts.map(post => (
                        <BlogCard mini key={post.id} img={post.media1.guid} title={post.title.rendered} link={post.slug}/>
                    ))}
                </div>
            </div>
        )
    }
}

export default MinifiedBlog
