import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import styles from './BlogCard.module.css'


class BlogCard extends Component {
    render() {
        const {mini} = this.props
        return (
            <Link onClick={() => window.scrollTo(0,0)} className={styles.link} to={`/blog/${this.props.link}`}>
                <div className={mini ? styles.containerMini : styles.container}>
                    <img alt='' src={this.props.img} className={mini ? styles.imgMini : styles.img} />
                    <h1 className={mini ? styles.h1Mini : ''}>{this.props.title}</h1>
                </div>
            </Link>
            
        )
    }
}

export default BlogCard
