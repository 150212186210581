import React, { Component } from 'react'
import styles from './MenuHandler.module.css'
import Menu from '../Menu'
import MenuIcon from '../../AppAssets/Icons/Hamburger.png'



class MenuHandler extends Component {
    constructor() {
        super()
        this.state = {
            menuVisible: false,
            spinInName: 'spinIn',
            spinOutName: 'spinOut',
            animation: {
                animationName: 'spinIn',
                animationStyle: 'ease-in-out',
                animationDuration: '0.2s'
            },
            iconVisible: {
                display: 'inline'
            }
        }

        this.onMenuClose = this.onMenuClose.bind(this)
    }

    componentDidMount() {
        
    }

    // spinIconIn() {
    //     this.setState({
    //         animation: {
    //             animationName: 'spinIn',
    //             animationStyle: 'ease-in-out',
    //             animationDuration: '0.2s'
    //         }
    //     })
    //     let styleSheet = document.styleSheets[0]

    //     let keyFrames = 
    //     `@keyframes ${this.state.spinInName} {
    //         0% {
    //             transform:  rotate(0deg);
    //             opacity: 1;
    //         }
    //         100% {
    //             transform:  rotate(360deg);
    //             opacity: 0;
    //         }
    //     }`

    //     styleSheet.insertRule(keyFrames, styleSheet.cssRules.length)

    //    setTimeout(() => {
    //        this.setState({
    //            iconVisible: {
    //                display: 'none'
    //            }
    //        })
    //    }, 180)
    // }

    // spinIconOut() {
         

    //     let styleSheet = document.styleSheets[0]

    //     let keyFrames = 
    //     `@keyframes ${this.state.spinInName} {
    //         0% {
    //             transform:  rotate(0deg);
    //             opacity: 0;
    //         }
    //         100% {
    //             transform:  rotate(45deg);
    //             opacity: 1;
    //         }
    //     }`

    //     styleSheet.insertRule(keyFrames, styleSheet.cssRules.length)

    //     this.setState({
    //         animation: {
    //             animationName: 'spinOut',
    //             animationStyle: 'ease-in-out',
    //             animationDuration: '0.2s'
    //         },
    //         iconVisible: {
    //             display: 'inline',
    //         }
    //     })
    // }

    onMenuButtonClick() {
        // this.spinIconIn()
        this.setState({
            menuVisible: true
        })
    }

    onMenuClose() {
        this.setState({
            menuVisible: false
        })
        // this.spinIconOut()
    }
    render() {
        return (
            <div className='tjena'>
                 {this.state.menuVisible ? 
                <Menu exlude={this.props.exlude} startPage={this.props.startPage} pages={this.props.pages} menuVisible={this.state.menuVisible} onClose={() => this.onMenuClose()} />
                :
                <img onClick={() => this.onMenuButtonClick()} src={MenuIcon} alt={'Menu'} className={styles.icon} />
                }
                

            </div>
        )
    }
}

export default MenuHandler
