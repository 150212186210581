import React, { Component } from 'react'
import styles from './ContactCard.module.css'
import Button from '../Button'
import hosts from '../../helpers/hosts'


class ContactCard extends Component {
    state = {
        contactInfo: '',
        didSubmit: false
    }

    onInputChange(event) {
        this.setState({contactInfo: event.target.value})
    }
    //WARNING: Fix fetch method
    onSubmit() {
        const {contactInfo} = this.state
        if (contactInfo !== '') {
            fetch(`${hosts.REGISTER_CONTACT_INFO}${contactInfo}`)
            this.setState({didSubmit: true})
        }
    }


    render() {
        return (
            <div className={[styles.card, this.props.className].join(' ')}>
                {this.state.didSubmit ?
                <div>
                    <p className={styles.p}>Tack!</p>
                    <p className={styles.p}>Vi kontaktar dig inom kort!</p>
                </div>
                :
                <div>
                    <p className={styles.p}>Vill du att vi ska kontakta dig?</p>
                    <p className={styles.p}>Fyll i din email eller ditt telefonnummer så hör vi av oss</p>
                    <input placeholder='Email eller telefonnummer...' className={styles.inputText} onChange={e => this.onInputChange(e)} type='text'></input>
                    <Button shouldNotScroll onClick={() => this.onSubmit()} title='Skicka' />
                </div>
                }
            </div>
        )
    }
}

export default ContactCard
