import React, { Component } from 'react'
import styles from './Contact.module.css'
import PageWithHTML from '../../Components/PageWithHTML'
import Button from '../../Components/Button'
import {isMobile} from 'react-device-detect'
import ContactCard from '../../Components/ContactCard/ContactCard'
import DecorContainer from '../../Components/DecorContainer'


const mapWidth = window.innerWidth < 600 ? window.innerWidth-50 : 500
const mapHeight = window.innerWidth < 600 ? 300 : 400
const googleMaps = `<iframe height=${mapHeight} width=${mapWidth} frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=Wopii%2C%20Kungsgatan&key=AIzaSyAfSeO7gK7b3OO0wOqpEpve4tY1NblXKO8" allowfullscreen></iframe>`

class Contact extends Component {
    render() {
        return (
            <div className={styles.container}>
            {isMobile ? 
                <div className={styles.contactFirst}>
                    <PageWithHTML className={styles.textContainerMobile} content={this.props.content}/>
                    <img className={styles.image} src={this.props.featuredMedia} alt="Wopii" />
                </div>
                :
                <div className={styles.contactFirst}>
                    <img className={styles.image} src={this.props.featuredMedia} alt="Wopii" />
                    <PageWithHTML className={styles.textContainer} content={this.props.content}/>
                </div>
            }
                <DecorContainer position='top-split'>
                    <div className={styles.contactForm}>
                        <PageWithHTML className={styles.maps} content={googleMaps} />
                        <ContactCard />
                    </div>
                </DecorContainer>
                
                
            </div>
        )
    }
}

export default Contact
