import * as types from './types'
import hosts from '../helpers/hosts'

export function requestPages() {
    return {
        type: types.REQUEST_PAGES
    }
}

export function recievePages(pages) {
    return {
        type: types.RECIEVE_PAGES,
        pages
    }
}

export function errorFetchingPages(error) {
    return {
        type: types.ERROR_WP,
        error,
        recievedAt: Date.now()
    }
}

/* ASYNC ACTION CREATORS */

function shouldFetchPages(state) {
    const pagesState = state.pages
    return !pagesState.isFetching && !pagesState.didRecieve
}

export function fetchPagesIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPages(getState())) {
            return dispatch(fetchPages())
        } else {
            return Promise.resolve()
        }
    }
}

export function fetchPages() {
    return async dispatch => {
        dispatch(requestPages)
        const response = await fetch(`${hosts.REST_API}/pages`)
        const json = await response.json()
        dispatch(recievePages(json))
    }   
}