import React, { Component } from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import FullScreen from '../../Components/FullScreen'
import styles from './Start.module.css'
import * as pagesActions from '../../R-Actions/pages'
import {removeHtmlTags} from '../../helpers/texts'
import DecorContainer from '../../Components/DecorContainer/DecorContainer'
import PageWithHTML from '../../Components/PageWithHTML'
import ImageGallery from '../../Components/ImageGallery/ImageGallery'
import Button from '../../Components/Button'
import ContactCard from '../../Components/ContactCard'
import image from '../../AppAssets/Images/wopii1-low.jpg'
import logo from '../../AppAssets/Images/logo.svg'

class Start extends Component {

    render() {
        if (this.props.placeholder) {
            return (
                <FullScreen className={styles.fullscreen}>
                    <h1 className={styles.sale}>IT-lösningar för små och stora företag</h1>
                    <img className={[styles.logo, styles.logoLoading].join(' ')} src={logo} alt='Wopii Logo' />
                    <img className={styles.startImage} src={image} alt='Wopii' />
                </FullScreen>
            )
        }
        return (
            <div>
                <div className={styles.decContainer}>
                    <div className={styles.topDecoration}></div>
                </div>
                <FullScreen className={styles.fullscreen}>
                        <h1 className={styles.sale}>{removeHtmlTags(this.props.description)}</h1>
                        <img className={styles.logo} src={logo} alt='Wopii Logo' />
                        <img className={styles.startImage} src={image} alt='Wopii' />
                </FullScreen>
                <DecorContainer color='green' >
                    <div className={styles.topContainer}>
                        <PageWithHTML className={styles.aboutText} content={this.props.secondaryContent} />
                        <img className={styles.deviceImage} src={this.props.featuredMedia} alt="Mobile device example" />
                    </div>
                </DecorContainer>    
                <ImageGallery gallery={this.props.gallery} />    
                <DecorContainer position='top' color='red'>
                    <div className={styles.contactContainer}>
                        <PageWithHTML className={styles.contactText} content={this.props.thirdContent} />
                        <Button className={styles.contactButton} title="Kontakta oss" linkTo='/kontakt' />
                    </div>
                </DecorContainer>     
                <div className={styles.descriptionContainer}>
                    <PageWithHTML content={this.props.content} />
                    <img className={styles.image} src={this.props.secondaryMedia} alt='Wopii' />
                </div>
                <DecorContainer position='top-edge' color='beige'>
                    <div className={styles.contactCard}>
                        <ContactCard />
                    </div>
                </DecorContainer>                                                       
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pages: state.pages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPagesIfNeeded: () => dispatch(pagesActions.fetchPagesIfNeeded())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Start)