import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styles from './ImageGallery.module.css'
import PageWithHTML from '../PageWithHTML'


class ImageGallery extends Component {
    state = {
        images: []
    }
    componentDidMount() {
        const gallery = ReactDOM.findDOMNode(this).getElementsByClassName('gallery')[0]
        if (gallery && typeof Node) {
            const imgs = gallery.querySelectorAll('img')
            const images = this.findImgInGallery(imgs)
            this.setState({images: images})
            gallery.parentNode.removeChild(gallery)
        }
    }

    findImgInGallery(images) {
        const imageSources = []
        for (let i = 0; i<images.length; i++) {
            imageSources.push(images[i].src)
        }
        return imageSources
    }

    render() {
        return (
            <div className={'gallery_parent'}>
                <PageWithHTML className={'gallery'} content={this.props.gallery} />
                <div className={styles.galleryContainer}>
                    {this.state.images.map(image => <div key={image} className={styles.imageContainer}><img className={styles.smallImages}  alt='logo' src={image} /></div> )}
                </div>
            </div>
        )
    }
}

export default ImageGallery
