import React, { Component } from 'react'
import styles from './BlogPost.module.css'
import PageWithHTML from '../PageWithHTML'
import Share from '../ShareButtons'


class BlogPost extends Component {
    getDate() {
        const {post} = this.props
        const date = new Date(post.date)
        return `${date.getDate()}/${date.getMonth()+1} ${date.getFullYear()}`
    }
    render() {
        const {post} = this.props
        return (
            <div className={styles.container}>
                <Share />
                <div className={styles.blogPost}>
                    <span className={styles.date}>{this.getDate()}</span>
                    <div className={styles.image}>
                        <img src={post.media1.guid} alt='postimage' />
                    </div>
                        <PageWithHTML content={post.content.rendered} />
                </div>
            </div>
        )
    }
}

export default BlogPost
