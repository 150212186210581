import React, { Component } from 'react'
import {connect} from 'react-redux'
import PageWithHTML from '../../Components/PageWithHTML'
import Button from '../../Components/Button'
import styles from './About.module.css'
import DecorContainer from '../../Components/DecorContainer/DecorContainer'
import { isMobile } from '../../helpers/device'

class About extends Component {

    renderProfile(pictureFirst, post, index) {
        if (pictureFirst) {
            return (
                <div key={index} className={styles.profile}>
                    <img className={styles.profileImage} src={post.profile_image.guid} alt="Profile" />
                    <div className={styles.profilePresentation}>
                        <h2 className={styles.h2}>{post.title.rendered}</h2>
                        <PageWithHTML className={styles.profileText} content={post.content.rendered} />
                    </div>
                </div>
            )
        } else {
            return (
                <div key={index} className={styles.profile}>
                    <div className={styles.profilePresentation}>
                        <h2 className={styles.h2}>{post.title.rendered}</h2>
                        <PageWithHTML className={styles.profileText} content={post.content.rendered} />
                    </div>
                    <img className={styles.profileImage} src={post.profile_image.guid} alt="Profile" />
                </div>
            )
        }
    }
    render() {
        const {persons} = this.props
        return (
            <div className={styles.container}>
                <DecorContainer color='green' >
                    <div className={styles.topContainer}>
                        <PageWithHTML className={styles.aboutText} content={this.props.content} />
                        <img className={styles.deviceImage} src={this.props.featuredMedia} alt="Mobile device example" />
                    </div>
                </DecorContainer>
                <div className={styles.contactContainer}>
                    <PageWithHTML className={styles.contactText} content={this.props.secondaryContent} />
                    <Button title="Kontakta oss" linkTo='/kontakt' />
                </div>
                <div className={styles.profileContainer}>
                    {persons.map((post, index) => {
                        if (isMobile) return this.renderProfile(true, post, index)
                        if (index % 2 === 0) {
                            return this.renderProfile(true, post, index)
                        } else {
                            return this.renderProfile(false, post, index)
                        }   
                    })}
                </div>
                <DecorContainer color='green' position='top'>
                    <div>
                        <PageWithHTML className={styles.contactText} content={this.props.thirdContent} />
                        <Button title="Kontakta oss" linkTo='/kontakt' />
                    </div>
                </DecorContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        persons: state.persons.persons
    }
}

export default connect(mapStateToProps)(About)