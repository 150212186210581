import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import styles from './Blog.module.css'
import BlogCard from '../../Components/BlogCard/BlogCard'
import BlogPost from '../../Components/BlogPost'
import Decor from '../../Components/DecorContainer'
import Mini from '../../Components/MinifiedBlog'
import ContactCard from '../../Components/ContactCard'


class Blog extends Component {
    render() {
        const {posts} = this.props
        return (
            <div className={styles.container}>
                <div className={styles.space}></div>
                <Switch>
                    <Route exact path='/blog' render={() => (
                        <div className={styles.blogCards}>
                        {posts.map(post => {
                            return (
                                <BlogCard key={post.id} img={post.media1.guid} title={post.title.rendered} link={post.slug}/>
                            )
                        })}
                    </div>
                    )} />
                    {posts.map(post => (
                    <Route key={post.id} path={`/blog/${post.slug}`} render={() => {
                        const filteredPosts = posts.filter(p => p.id !== post.id).sort((a,b) => b.id - a.id)
                        return (
                            <div>
                                <BlogPost post={post} />
                                <Mini posts={filteredPosts} />
                            </div>
                        )
                        }} />
                    ))}
                </Switch>
                <Decor position='top'>
                    <div className={styles.contact}>
                        <ContactCard />
                    </div>
                </Decor>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        posts: state.posts.posts
    }
}

export default withRouter(connect(mapStateToProps)(Blog))