import * as types from './types'
import hosts from '../helpers/hosts'

export function requestPosts() {
    return {
        type: types.REQUEST_POSTS
    }
}

export function recievePosts(posts) {
    return {
        type: types.RECIEVE_POSTS,
        posts
    }
}

export function errorFetchingPosts(error) {
    return {
        type: types.ERROR_WP,
        error,
        recievedAt: Date.now()
    }
}

/* ASYNC ACTION CREATORS */

function shouldFetchPosts(state) {
    const postsState = state.posts
    return !postsState.isFetching && !postsState.didRecieve
}

export function fetchPostsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPosts(getState())) {
            return dispatch(fetchPosts())
        } else {
            return Promise.resolve()
        }
    }
}

export function fetchPosts() {
    return async dispatch => {
        dispatch(requestPosts)
        const response = await fetch(`${hosts.REST_API}/posts`)
        const json = await response.json()
        dispatch(recievePosts(json))
    }   
}