import React, { Component } from 'react'
import FullScreen from '../../Components/FullScreen'
import styles from './styles'
import {getText} from '../../helpers/texts'

class NotFound extends Component {
    render() {
        return (
            <div>
                
                <FullScreen style={styles.fullscreen}>
                    <p>{getText('Sidan du letar efter finns inte')}</p>
                </FullScreen>                                                                           
            </div>
        )
    }
}

export default NotFound