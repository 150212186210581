import React, { Component } from 'react'

class PageWithHTML extends Component {
    
    render() {
        
        return (
                <div className={this.props.className}>
                    <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                </div>
        )
    }
}

export default PageWithHTML
