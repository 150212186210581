import * as types from '../R-Actions/types'

const initialState = {
    isFetching: false,
    persons: [],
    didRecieve: false,
    error: null,
}

export default function personReducer(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_PERSON:
            return Object.assign({}, state, {
                isFetching: true,
                didRecieve: false
            })
        case types.RECIEVE_PERSON:
            return Object.assign({}, state, {
                isFetching: false,
                didRecieve: true,
                persons: action.persons
            })
        case types.ERROR_WP:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            })
        default:
         return state
    }
}
