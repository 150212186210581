import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import styles from './Button.module.css'


class Button extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: false
        }
        this.mouseEnter = this.mouseEnter.bind(this)
        this.mouseLeave = this.mouseLeave.bind(this)
    }

    mouseEnter() {
        this.setState({hover: true})
    }
    mouseLeave() {
        this.setState({hover: false})
    }
    onClick() {
        let shouldScroll = true
        if (this.props.shouldNotScroll) shouldScroll = false
        if (shouldScroll) window.scrollTo(0,0)
        if (this.props.onClick) {
            this.props.onClick()
        }
    }
    render() {
        const {hover} = this.state
        const shadow = hover ? styles.shadowHover : styles.shadow
        return (
            <div onClick={() => this.onClick()} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} className={[styles.button, this.props.className].join(' ')}>
                {this.props.linkTo ? 
                <Link className={styles.p} to={this.props.linkTo}>{this.props.title}</Link>
                :
                <p className={styles.p}>{this.props.title}</p>
                }
                
            </div>
        )
    }
}

export default Button
