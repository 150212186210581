import * as types from '../R-Actions/types'

const initialState = {
    isFetching: false,
    posts: [],
    didRecieve: false,
    error: null,
}

export default function postsReducer(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_POSTS:
            return Object.assign({}, state, {
                isFetching: true,
                didRecieve: false
            })
        case types.RECIEVE_POSTS:
            return Object.assign({}, state, {
                isFetching: false,
                didRecieve: true,
                posts: action.posts
            })
        case types.ERROR_WP:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            })
        default:
         return state
    }
}
