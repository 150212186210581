import React, { Component } from 'react'
import {connect} from 'react-redux'
import * as pagesActions from './R-Actions/pages'
import * as postsActions from './R-Actions/posts'
import * as personActions from './R-Actions/person'
import WPRouter from '../App/Components/WPRouter'
import Header from '../App/Components/Header'
import logo from './AppAssets/Images/logo.svg'
import Start from './Pages/Start'


class AppContainer extends Component {
    componentDidMount() {
        this.props.fetchPagesIfNeeded()
        this.props.fetchPostsIfNeeded()
        this.props.fetchPersonIfNeeded()
    }
    render() {
        const {pagesState, postsStage} = this.props
        console.log(pagesState)
        console.log(postsStage)
        return (
            !this.props.pagesState.didRecieve ?
            <div className='loading-window'>
                <img src={logo} alt="Wopii" className='loading-logo' />
            </div>
            // <Start placeholder={true} />
            :
            <div style={{width: '100vw', overflow: 'hidden'}}>
                <WPRouter startPage={'start.php'} pages={pagesState.pages} posts={postsStage.posts}>
                    <Header exludeFromMenu={'start'} pages={pagesState.pages} />
                </WPRouter>
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        pagesState: state.pages,
        postsStage: state.posts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPagesIfNeeded: () => dispatch(pagesActions.fetchPagesIfNeeded()),
        fetchPostsIfNeeded: () => dispatch(postsActions.fetchPostsIfNeeded()),
        fetchPersonIfNeeded: () => dispatch(personActions.fetchPersonIfNeeded())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)