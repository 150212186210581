import * as types from '../R-Actions/types'

const initialState = {
    isFetching: false,
    pages: [],
    didRecieve: false,
    error: null,
}

export default function pagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_PAGES:
            return Object.assign({}, state, {
                isFetching: true,
                didRecieve: false
            })
        case types.RECIEVE_PAGES:
            return Object.assign({}, state, {
                isFetching: false,
                didRecieve: true,
                pages: sortPagesByMenuOrder(action.pages)
            })
        case types.ERROR_WP:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            })
        default:
         return state
    }
}

function sortPagesByMenuOrder(origin) {
    origin.sort((a, b) => a.menu_order-b.menu_order)
    return origin
}