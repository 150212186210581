import React, { Component } from 'react'
import styles from './Header.module.css'
import MenuHandler from '../MenuHandler'
import Facebook from '../../AppAssets/Icons/facebooklogo.png'
import LinkedIn from '../../AppAssets/Icons/linkedinlogo.png'
import Email from '../../AppAssets/Icons/email.png'
import {Link} from 'react-router-dom'
// import logo from '../../AppAssets/Images/logo.png'


class Header extends Component {
    constructor() {
        super()

        this.state = {
            menuVisible: false,
            positionZero: true
        }

        
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.setTransparent())
    }

    setTransparent() {        
        this.setState({positionZero: window.pageYOffset === 0})
    }

    
    render() {
        return (
            <div className={styles.container}>
                <div className={[styles.header, this.state.positionZero ? styles.transparent : styles.gradient].join(' ')}>
                    <MenuHandler exlude={this.props.exludeFromMenu} startPage={{route: '/', title: 'Hem'}} pages={this.props.pages} />
                    {/* <a onClick={() => this.onMenuButtonClick()}>Menu</a> */}
                    {/* <div className={styles.center}> */}
                        {/* <img src={logo} alt='Wopii' className={styles.logo}/> */}
                    {/* </div> */}
                    <div>
                        <a href='https://www.facebook.com/WopiiDevelopment/' target='_blank'><img src={Facebook} alt={'Facebook'} className={styles.icon} /></a>
                        <a href='https://www.linkedin.com/company/wopii-development/' target='_blank'><img src={LinkedIn} alt={'Linkedin'} className={styles.icon} /></a>
                        <Link to='/kontakt'>
                            <img src={Email} alt={'Email'} className={styles.icon} />
                        </Link>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
