import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Page from '../Page'
import NotFound from '../../Pages/NotFound'
  

class WPRouter extends Component {
    render() {
        return (
            <Router> 
                    <div>
                        {this.props.children}
                        <Switch>
                            {this.props.pages.map(page => {
                                if (page.template === this.props.startPage) {
                                    return (
                                        <Route key={page.id} exact path="/" render={() => (
                                            <Page 
                                            pages={this.props.pages}
                                            template={page.template} 
                                            content={page.content.rendered}
                                            secondaryContent={page.secondary_content}
                                            thirdContent={page.third_content}
                                            featuredMedia={page.featured_media.guid}
                                            gallery={page.gallery}
                                            title={page.title.rendered}
                                            metaImage={page.meta_image.guid}
                                            secondaryMedia={page.secondary_media.guid}
                                            description={page.description}/>)} />
                                    )
                                    } else {
                                        return (
                                            <Route key={page.id} path={`/${page.slug}`} render={() => (
                                                <Page 
                                                pages={this.props.pages}
                                                template={page.template} 
                                                content={page.content.rendered} 
                                                secondaryContent={page.secondary_content}
                                                thirdContent={page.third_content}
                                                featuredMedia={page.featured_media.guid}
                                                gallery={page.gallery}
                                                title={page.title.rendered}
                                                description={page.description}/>)} />
                                        )
                                    }
                                    
                                })
                            }
                            
                            <Route component={NotFound} />
                        </Switch>
                    </div>
            </Router>
        )
    }
}

export default WPRouter
