import React, { Component } from 'react'
import styles from './Menu.module.css'
import FullScreen from '../FullScreen'
import closeMenuIcon from '../../AppAssets/Icons/CloseMenu.png'
import {Link} from 'react-router-dom'


class Menu extends Component {
    constructor() {
        super()
        this.state = {
            fadeInName: 'menuExpandAnimation',
            fadeOutName: 'menuCollapseAnimation',
            animation: {
                animationName: 'menuExpandAnimation',
                animationStyle: 'ease-in-out',
                animationDuration: '0.2s'
            },
            display: {
                display: 'none'
            },
            show: false
        }
    }

    componentDidMount() {
       if (this.props.menuVisible) {
           this.fadeIn()
       }
    }

    fadeIn() {
        this.setState({show: true})
        // this.setState({
        //     display: {
        //         display: 'inline'
        //     }
        // })
        // let styleSheet = document.styleSheets[0]
        // console.log(document.styleSheets)

        // let keyFrames = 
        // `@keyframes ${this.state.fadeInName} {
        //     0% {opacity:  0}
        //     100% {opacity: 1}
        // }`

        // styleSheet.insertRule(keyFrames, styleSheet.cssRules.length)
    }

    fadeOut() {
        this.setState({show: false})
        // this.setState({
        //     animation: {
        //         animationName: this.state.fadeOutName,
        //         animationStyle: 'ease-in-out',
        //         animationDuration: '0.2s'
        //     }
        // })
        // let styleSheet = document.styleSheets[0]

        // let keyFrames = 
        // `@keyframes ${this.state.fadeOutName} {
        //     0% {opacity:  1}
        //     100% {opacity: 0}
        // }`

        // styleSheet.insertRule(keyFrames, styleSheet.cssRules.length)
        
    }

    onClose(scrollToTop) {
        if (scrollToTop) {
            window.scrollTo(0,0)
        } 
        this.fadeOut()
        setTimeout(() => {
            this.setState({
                display: {
                    display: 'none'
                }
            })
            this.props.onClose()
        }, 150)
    }

    render() {
        
        return (
            <FullScreen ref={'menu'} className={[styles.container, this.state.show ? '' : styles.exit].join(' ')}>
                <img className={styles.icon} alt={'Close menu'} onClick={() => this.onClose(false)} src={closeMenuIcon} />
                <ul className={styles.menuUl}>
                {this.props.startPage ?
                    <li className={styles.menuLi}>
                        <Link className={styles.link} to={this.props.startPage.route} onClick={() => this.onClose(true)}>{this.props.startPage.title}</Link>
                    </li>
                    :
                    null
                }
                {
                    this.props.pages.map(page => {
                        if (page.slug != this.props.exlude) {
                            return (
                                <li className={styles.menuLi} key={page.id}>
                                    <Link className={styles.link} to={`/${page.slug}`} onClick={() => this.onClose(true)}>{page.title.rendered}</Link>
                                </li>
                            )
                        }
                        
                    })
                }
                </ul>
            </FullScreen>
        )
    }
}

export default Menu
