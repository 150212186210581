import React, { Component } from 'react'
import AppContainer from '../src/App/AppContainer'
import {Provider} from 'react-redux'
import store from './App/Config/store'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppContainer />
      </Provider>
    )
  }
}

export default App
