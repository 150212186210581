import React, { Component } from 'react'
import styles from './ShareButtons.module.css'
import {
    FacebookShareButton, 
    FacebookIcon, 
    RedditShareButton, 
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'


class ShareButtons extends Component {
    render() {
        const url = window.location.href
        const size = 40
        return (
            <div className={styles.container}>
                <div className={styles.buttonContainer}>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={size} round/>
                    </FacebookShareButton>
                </div>
                <div className={styles.buttonContainer}>
                    <RedditShareButton url={url}>
                        <RedditIcon size={size} round/>
                    </RedditShareButton>
                </div>
                <div className={styles.buttonContainer}>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={size} round/>
                    </LinkedinShareButton>
                </div>
                <div className={styles.buttonContainer}>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={size} round/>
                    </TwitterShareButton>
                </div>
                
            </div>
        )
    }
}

export default ShareButtons
