import React, { Component } from 'react'
import styles from './DecorContainer.module.css'



class DecorContainer extends Component {
    getBackgroundColor() {
        switch(this.props.color) {
            case 'green':
                return styles.green
            case 'red':
                return styles.red
            case 'beige':
                return styles.beige
            case 'blue':
                return styles.blue
            default:
                return styles.green
        }
    }
    getEdgeColor() {
        switch(this.props.color) {
            case 'green':
                return styles.greenEdge
            case 'red':
                return styles.redEdge
            case 'beige':
                return styles.beigeEdge
            case 'blue':
                return styles.blueEdge
            default:
                return styles.greenEdge
        }
    }
    getShape() {
        switch(this.props.position) {
            case 'top':
                return (
                    <div className={styles.overflow}>
                        <div className={[styles.top, this.getBackgroundColor()].join(' ')}></div>
                        <div className={[styles.container, this.getBackgroundColor()].join(' ')}>
                            {this.props.children}
                        </div>
                    </div>
                )
            case 'bottom':
                return (
                    <div className={styles.overflow}>
                        <div className={[styles.container, this.getBackgroundColor()].join(' ')}>
                            {this.props.children}
                        </div>
                        <div className={[styles.bottom, this.getBackgroundColor()].join(' ')}></div>
                    </div>
            )
            case 'top-edge':
                return (
                    <div className={styles.overflow}>
                        <div className={[styles.edgeTop, this.getEdgeColor()].join(' ')}></div>
                        <div className={[styles.container, this.getBackgroundColor()].join(' ')}>
                            {this.props.children}
                        </div>
                    </div>
                )
            case 'top-split':
                return (
                    <div className={styles.overflow}>
                        <div className={styles.splitTop}>
                            {this.props.children}
                        </div>    
                        <div className={styles.splitTopFill}></div>
                    </div>
                    
                    
                )
            default: 
                return (
                    <div className={styles.overflow}>
                        <div className={[styles.container, this.getBackgroundColor()].join(' ')}>
                            {this.props.children}
                        </div>
                        <div className={[styles.bottom, this.getBackgroundColor()].join(' ')}></div>
                    </div>
                )    
        }
    }
    render() {
        return (
            this.getShape()
        )
        
    }
}

export default DecorContainer
