import React, { Component } from 'react'
import styles from './Fullscreen.module.css'


class FullScreen extends Component {
    render() {
        return (
            // <div className={`${styles.container} ${this.props.className}`}>
            <div className={[styles.container, this.props.className].join(' ')}>
                {this.props.children}
            </div>
        )
    }
}

export default FullScreen
