import * as types from './types'
import hosts from '../helpers/hosts'

export function requestPerson() {
    return {
        type: types.REQUEST_PERSON
    }
}

export function recievePerson(persons) {
    return {
        type: types.RECIEVE_PERSON,
        persons
    }
}

export function errorFetchingPerson(error) {
    return {
        type: types.ERROR_WP,
        error,
        recievedAt: Date.now()
    }
}

/* ASYNC ACTION CREATORS */

function shouldFetchPerson(state) {
    const personState = state.persons
    return !personState.isFetching && !personState.didRecieve
}

export function fetchPersonIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPerson(getState())) {
            return dispatch(fetchPerson())
        } else {
            return Promise.resolve()
        }
    }
}

export function fetchPerson() {
    return async dispatch => {
        dispatch(requestPerson)
        const response = await fetch(`${hosts.REST_API}/person?filter[orderby]&order=asc`)
        const json = await response.json()
        dispatch(recievePerson(json))
    }   
}